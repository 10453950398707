/**
 * Every behavior is required to specify a selector.
 *
 * A string selector suggests that the behavior needs DOM to
 * be ready and the connection happens after Turbo load.
 * Try to attach delegated events as close as possible to the
 * target elements. See Event performance at https://api.jquery.com/on/
 * for details.
 *
 * A document selector means we can connect the behavior immediately.
 * This is necessary in some cases (e.g. lazysizes).
 */
interface BehaviorClass {
  new (element: JQuery<Node>): BehaviorComponentInstance;
  selector: "string" | Document;
}

interface BehaviorComponentInstance extends BehaviorClass {
  teardown?(): void;
}

export default class BehaviorsConnector {
  private componentsInstances: BehaviorComponentInstance[] = [];
  private behaviors: BehaviorClass[] = [];
  private observer = new MutationObserver(this.processNewNodes.bind(this));

  constructor(...behaviorClasses: BehaviorClass[]) {
    this.behaviors.push(...behaviorClasses);
  }

  connect() {
    this.assertSelectorsAreDefined();
    this.setupListeners();
  }

  processNewNodes(mutationList: MutationRecord[]) {
    mutationList.forEach((mutation) => {
      mutation.addedNodes.forEach((node) => {
        this.addBehaviorsToComponents($(node));
      });
    });
  }

  setupListeners() {
    this.addBehaviorsToDocument();
    document.addEventListener("turbo:load", () => {
      this.addBehaviorsToComponents($(document));
      // Narrow this down to mutations we care about (e.g. changes
      // to head are triggered by browser extensions and 3rd party scripts):
      const targetNode = document.querySelector("body");
      this.observer.observe(targetNode, { childList: true, subtree: true });

      // Trigger a custom event for any logic that should execute after all the
      // other behaviors have executed.
      $(document).trigger("vmx:behaviors-connected");
    });

    document.addEventListener("turbo:render", () => {
      this.addBehaviorsToComponents($(document));
      // Narrow this down to mutations we care about (e.g. changes
      // to head are triggered by browser extensions and 3rd party scripts):
      const targetNode = document.querySelector("body");
      this.observer.observe(targetNode, { childList: true, subtree: true });

      // Trigger a custom event for any logic that should execute after all the
      // other behaviors have executed.
      $(document).trigger("vmx:behaviors-connected");
    });

    document.addEventListener("turbo:before-cache", () => {
      this.observer.disconnect();
      this.removeBehaviorsFromComponents();
    });
  }

  addBehaviorsToComponents($container: JQuery<Node>) {
    this.behaviors.forEach((Behavior) => {
      if (typeof Behavior.selector === "string") {
        $container
          .find(Behavior.selector)
          .addBack(Behavior.selector)
          .each((_index, componentContainer) => {
            this.componentsInstances.push(new Behavior($(componentContainer)));
          });
      }
    });
  }

  addBehaviorsToDocument() {
    this.behaviors.forEach((Behavior) => {
      const { selector } = Behavior;
      if (selector instanceof Document) {
        this.componentsInstances.push(new Behavior($(selector)));
      }
    });
  }

  removeBehaviorsFromComponents() {
    this.componentsInstances.forEach((component) => {
      if (component.teardown) component.teardown();
    });
    this.componentsInstances = [];
  }

  assertSelectorsAreDefined() {
    this.behaviors.forEach((Behavior) => {
      if (typeof Behavior.selector === "undefined") {
        const error = `Behavior ${Behavior.name} failed to specify a selector.`;
        throw new Error(error);
      }
    });
  }
}
