export default class Popover {
  static selector = "[data-bs-toggle='popover']";
  closeButtonSelector = ".close-popover";

  constructor($container) {
    $(() => {
      this.container = $container.popover();

      if (this.container.data("bs-trigger") === "click") this.setupClickHandlers();
    });
  }

  setupClickHandlers() {
    this.hideOnClickOutside = this.hideOnClickOutside.bind(this);
    this.hideOnClickClose = this.hideOnClickClose.bind(this);

    this.container.on("shown.bs.popover", () => {
      $(document).on("click", this.hideOnClickOutside);
      $(document).on("click", this.closeButtonSelector, this.hideOnClickClose);
    });
    this.container.on("hidden.bs.popover", () => {
      this.removeClickHandlers();
    });
  }

  removeClickHandlers() {
    $(document).off("click", this.hideOnClickOutside);
    $(document).off("click", this.closeButtonSelector, this.hideOnClickClose);
  }

  hideOnClickClose() {
    this.container.popover("hide");
  }

  // NOTE: This function handles the closing of a popover when clicking outside
  // the popover div, effectively limiting the user to one open popover at
  // a time. Removing this behavior (allowing more than one popover to
  // be open) will cause `hideOnClickClose` to behave unintuitively in that it
  // would close _all_ open popovers if more than one were open, despite
  // only clicking the close button on one popover.
  hideOnClickOutside(clickEvent) {
    const popoverId = this.container.attr("aria-describedby");

    if ($(clickEvent.target).parents(`#${popoverId}`).length === 0) {
      this.container.popover("hide");
    }
  }

  teardown() {
    this.container.popover("hide");
  }
}
